import React from 'react';
import Collapsible from 'react-collapsible';
import Icon from '../../../../assets/images/expand-icon.png';
import MenuCategory from '../MenuCategory';
import { menuWithoutUnsuitableItems } from '../../../../helpers/menu';
import './MenuSection.scss';

const MenuSection = ({ showUnsuitable, menu, requirements, openDefault, branchId }) => {
  const captureEvent = () => {
    window.posthog.capture("open_menu_section", {
      branch: branchId,
      menu: menu.name,
    });
  };

  const renderTrigger = () => {
    return <div className="section-trigger">
      <p>{menu.name}</p>
      <img src={Icon} alt=""/>
    </div>;
  };

  const filteredMenu = showUnsuitable ? menu : menuWithoutUnsuitableItems(menu, requirements);
  const filteredCategories = filteredMenu.categories.filter(c => c.items.length > 0 && c.visible);

  return <Collapsible classParentString="MenuSection" trigger={renderTrigger()} open={openDefault} onOpen={captureEvent}>
    {filteredCategories.map((c, i) => <MenuCategory key={i} category={c} requirements={requirements} branchId={branchId}/>)}
    {filteredCategories.length === 0
    ? <p className="none-available">No items in this menu match your criteria</p>
    : null}
  </Collapsible>;
};

export default MenuSection;