import React from 'react';
import Collapsible from 'react-collapsible';
import ActiveIcon from '../../../../assets/images/expand-icon.png';
import InactiveIcon from '../../../../assets/images/expand-icon-disabled.png';
import MenuItem from '../MenuItem';
import './MenuCategory.scss';

const MenuCategory = ({ category, branchId, requirements }) => {
  const captureEvent = () => {
    window.posthog.capture("open_menu_category", {
      category: category.name,
    });
  };

  const renderTrigger = () => {
    return <div className="category-trigger">
      <p>{category.name}</p>
      <img className="inactive" src={InactiveIcon} alt=""/>
      <img className="active" src={ActiveIcon} alt=""/>
    </div>;
  };

  const items = category.items.filter(item => item.hidden_branches.every(branch => parseInt(branch) !== parseInt(branchId)));

  if (items.length === 0) {
    return null;
  }

  return <Collapsible classParentString="MenuCategory" trigger={renderTrigger()} onOpen={captureEvent}>
    {items.map((item, i) => <MenuItem key={i} item={item} requirements={requirements}/>)}
  </Collapsible>;
};

export default MenuCategory;
