import React from 'react';
import Collapsible from 'react-collapsible';
import TrafficIcon from '../TrafficIcon';
import ActiveIcon from '../../../../assets/images/expand-icon.png';
import InactiveIcon from '../../../../assets/images/expand-icon-disabled.png';
import { groupedAllergens, suitableColor } from '../../../../helpers/menuItem';
import './MenuItem.scss';
import { humanizeRequirement } from '../../../../store/requirements';

export const MenuItem = ({ item, requirements }) => {
  const captureEvent = () => {
    window.posthog.capture("open_menu_item", {
      id: item.id,
      item: item.name,
    });
  };

  const renderTrigger = (color) => {
    return <div className="item-trigger">
      <TrafficIcon color={color}/>
      <p>{item.name}</p>
      <img className="inactive" src={InactiveIcon} alt=""/>
      <img className="active" src={ActiveIcon} alt=""/>
    </div>;
  };

  const renderInfo = (color) => {
    return <p className={`info ${color}`}>
      {item.notes}
    </p>;
  };

  const renderSuitableFor = () => {
    const renderRow = (key, humanizedKey) => {
      let color = 'red';

      switch (item[key]) {
        case 'yes':
          color = 'green';
          break;
        case 'no':
          color = 'red';
          break;
        case 'modifiable':
          color = 'orange';
          break;
      }

      return <div className="row">
        <TrafficIcon color={color}/>
        <p>{humanizedKey}</p>
      </div>;
    };

    return <div className="suitable-for">
      <div className="body">
        {renderRow("vegetarian_friendly", "Vegetarian")}
        {renderRow("vegan_friendly", "Vegan")}
        {/* { renderRow("gluten_friendly", "Gluten Free") } */}
      </div>
    </div>;
  };

  const renderAllergens = () => {
    const data = groupedAllergens(item);

    const renderSection = (key, humanizedKey, color) => {
      if (!data[key]) {
        return;
      }

      return <div className="row">
        <div className="upper">
          <TrafficIcon color={color}/>
          <p>{humanizedKey}</p>
        </div>

        <div className="lower">
          <p>{(
            data[key] || []
          ).map(humanizeRequirement).join(', ')}</p>
        </div>
      </div>;
    };

    return <div className="allergens">
      {renderSection('free_from', 'Free from', 'green')}
      {renderSection('modifiable', 'Ask to modify', 'blue')}
      {renderSection('cross_contamination', 'Cross contamination', 'orange')}
      {renderSection('contains', 'Contains', 'red')}
    </div>;
  };

  const renderIngredients = () => {
    return item.ingredients ? <div className="ingredients">
      <h4>Ingredients</h4>
      {item.ingredients}
    </div> : <></>;
  };

  const color = suitableColor(item, requirements);

  return <Collapsible classParentString="MenuItem" trigger={renderTrigger(color)} onOpen={captureEvent}>
    <div className="inner">
      {renderInfo(color)}
      {renderSuitableFor()}
      {renderAllergens()}
      {renderIngredients()}
    </div>
  </Collapsible>;
};