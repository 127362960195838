import React from 'react';
import './TrafficIcon.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle, faExclamation, faM, faTimes } from "@fortawesome/free-solid-svg-icons";

const TrafficIcon = ({ color }) => {
  let img = null;

  switch (color) {
    case 'red':
      img = <span className="traffic-icon red fa-layers fa-fw"><FontAwesomeIcon icon={faCircle} size={"lg"}/><FontAwesomeIcon icon={faTimes} transform={'shrink-2'}/></span>;
      break;
    case 'orange':
      img = <span className="traffic-icon orange fa-layers fa-fw"><FontAwesomeIcon icon={faCircle} size={"lg"}/><FontAwesomeIcon icon={faExclamation} transform={'shrink-2'}/></span>;
      break;
    case 'blue':
      img = <span className="traffic-icon blue fa-layers fa-fw"><FontAwesomeIcon icon={faCircle} size={"lg"}/><FontAwesomeIcon icon={faM} transform={'shrink-4'}/></span>;
      break;
    case 'green':
      img = <span className="traffic-icon green fa-layers fa-fw"><FontAwesomeIcon icon={faCircle} size={"lg"}/><FontAwesomeIcon icon={faCheck} transform={'shrink-2'}/></span>;
      break;
  }

  return <>{img}</>;
};

export default TrafficIcon;