import React from 'react';
import './Disclaimer.scss';

export default function DisclaimerScene({ branch, navigate }) {
  const accept = () => {
    navigate('requirements');
  };

  let logo = <p>{branch.full_name}</p>;
  if (branch.logo_url) {
    logo = <img src={branch.logo_url} alt="Logo"/>;
  }

  return <div className="DisclaimerScene scene">
    <div className="logo-container">
      {logo}
    </div>

    <div className="details">
      <h1>Disclaimer</h1>
      <p>{branch.primary_disclaimer}</p>
    </div>

    <div className="actions">
      <button className="primary" onClick={accept}>Accept</button>
    </div>
  </div>;
}
